<!--
 * @Author: Banbri
 * @Date: 2021-05-11 15:20:47
 * @LastEditors: Banbri
 * @LastEditTime: 2021-05-11 16:44:32
 * @Description: 订单退款页面
-->
<template>
  <div class="order-refund" id="order-refund" name="order-refund">
    <div class="order-refund-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="order-refund-content">
            <div class="extra"></div>
          </div>
          <div class="order-refund-title">
            <p>申请退款</p>
          </div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="申请类型">
              <el-select v-model="form.type" placeholder="请选择申请类型">
                <el-option label="我要退款(无需退货)" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请原因">
              <el-select v-model="form.reason" placeholder="请选择申请原因">
                <el-option label="多拍、错拍、不想要" value="1"></el-option>
                <el-option label="协商一致退款" value="2"></el-option>
                <el-option label="缺货" value="3"></el-option>
                <el-option label="发货慢" value="4"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="退款金额">
              <el-input v-model="form.money" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="退款说明">
              <el-input
                type="textarea"
                placeholder="必填，请您详细填写申请说明"
                v-model="form.desc"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交申请</el-button>
              <el-button @click="cancel()">取消</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CenterMenu from '../components/CenterMenu'
export default {
  components: {
    CenterMenu
  },
  data() {
    return {
      orderNum: '',
      form: {
        type: '',
        reason: '',
        money: '',
        desc: ''
      }
    }
  },
  activated() {
    this.orderNum = this.$route.query.orderNum
  },
  methods: {
    onSubmit() {
      // console.log('submit!')
    },
    cancel(){
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.order-refund {
  max-width: 1225px;
  margin: 0 auto;
}
.order-refund-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.order-refund-title {
  height: 100px;
  display: flex;
  align-items: center;
}
.order-refund-title p {
  font-size: 30px;
  color: #757575;
  margin-left: 50px;
}
.extra {
  height: 10px;
}
.el-input {
  width: 80px;
}
.el-textarea {
  width: 40%;
}
</style>